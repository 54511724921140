import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import iconemail from '../img/2x/icon-email.png'
const MailConfirm = () => ( <Layout>
  <div className="skipScroll">
  <Helmet
  title={'Check your email inbox or spam folder | temtum'}
  meta={[
      {
        name: 'description',
        content: ''
      }
    ]}
    />
    <div className="hd-space"/>
    <div className="page">
      <section id="" className="skipScroll bg-blue-2X text-whiteX bg1X">
        <div className="bluepurpleX video-overlayX"/>
        <div className="container my-5">
          <div className="row">
            <div className="col-lg-8 mx-auto text-center">
              <img src={iconemail} width="64px" height="64px" alt=""/>
              <h4 className="display-2 font-weight-bold mb-3">
                You're Almost There!
              </h4>
              <h4 className="display-3 font-weight-boldX mb-3">
                Please check your email to confirm
              </h4>
              <h5 className="display-4  text-grey mb-3">
                Your request has been received and information has been sent to you.
              </h5>
              <p className="mb-4">
                IMPORTANT! To make sure that our mail reaches your inbox correctly and is not blocked by mistake, please add our email address to your whitelist. newsletter@temtum.com and main_launch_list@getresponse.com to your email address book and/or to "whitelist" (trusted senders).
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</Layout> )
export default MailConfirm
